/** @format */

import { registerApplication, start } from 'single-spa'
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from 'single-spa-layout'
import { ErrorParcel } from '@teamhub/error-page'
import './global.css'

// see the <single-spa-router> section of index.ejs to see the layout
const routes = constructRoutes(document.querySelector('#single-spa-layout'), {
  errors: {
    appError: ErrorParcel,
  },
})


const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name)
  },
})
constructLayoutEngine({ routes, applications })

// mount navbar separate from the layout engine
// to prevent single spa router from refreshing between navigation
const navbarApp = {
  name: '@teamhub/navbar',
  app: () => System.import('@teamhub/navbar'),
  activeWhen: () => !location.pathname.match(/^\/(login)/i),
  customProps: {
    domElementGetter() {
      return document.querySelector('#appshell__navigation')
    },
  },
}
registerApplication(navbarApp)

applications.forEach((app) => {
  // only show this guy when login isn't up
  if (app.name === '@teamhub/dashboard-portal') {
    app.activeWhen = (location) => location.pathname === '/'
  }
  registerApplication(app)
})

start({
  urlRerouteOnly: true,
})
